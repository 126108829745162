/* open-sans-regular - latin_latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/open-sans-v16-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('fonts/open-sans-v16-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/open-sans-v16-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/open-sans-v16-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */ url('fonts/open-sans-v16-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/open-sans-v16-latin_latin-ext-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin_latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/open-sans-v16-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('fonts/open-sans-v16-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/open-sans-v16-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/open-sans-v16-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */ url('fonts/open-sans-v16-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/open-sans-v16-latin_latin-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

h1 {
    font-size: 2em;
}

.common__form {
    margin-top: 2em;
    max-width: 550px;
}

.common__top-button-bar {
    padding-bottom: 1em;
}

.common__top-button-bar .ant-btn {
    margin-right: 0.5em;
}

.common__form-buttons .ant-btn {
    margin-top: 1em;
    margin-right: 0.5em;
}

.common__breadcrumb {
    margin-bottom: 1em;
}

.common__radiogroup--vertical .ant-radio-wrapper {
    display: block;
    height: 30px;
    line-height: 30px;
}

.common__details {
    background-color: #fff;
    max-width: 700px;
    margin-bottom: 1.5em;
}

.collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ant-form-item {
    margin-bottom: 8px;
}

h2 + .common__form {
    margin-top: 1em;
}

.home-page__logo {
    height: 60px;
    width: 184px;
    text-align: left;
    display: inline-block;
    float: left;
}

.home-page__logo a {
    color: #000;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
}

.home-page__logo img {
    max-height: 60px;
    position: static;
    max-width: 180px;
    vertical-align: top;
}

